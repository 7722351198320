import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row, Tab, Table, Tabs } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { ZZZStat } from '../../../modules/zzz/common/components/zzz-stat';

const ZZZAgentsDiskDrivesStats: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Drive Disks Stats</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_disk2.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Drive Disks Stats</h1>
          <h2>
            Drive Disks Stats from Zenless Zone Zero explained - everything you
            can roll on the gear explained.
          </h2>
          <p>
            Last updated: <strong>04/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          If you want to{' '}
          <strong>
            learn more about Drive Disks themselves, do check our other guide
          </strong>{' '}
          as here you will find only information about the main and sub-stats
          that you can roll on them.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Drive Disks"
            link="/zenless/guides/disk-drives-about"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_disk.png"
                alt="Drive Disks"
              />
            }
          />
        </Row>
        <SectionHeader title="Stats intro" />
        <h5>Main Stats</h5>
        <p>
          Each Drive Disk has one main stat and up to 4 sub-stats. Depending on
          the Drive Disk slot it fits in, the possible main stats are:
        </p>
        <ul>
          <li>
            <strong>I</strong> - Flat HP
          </li>
          <li>
            <strong>II</strong> - Flat ATK
          </li>
          <li>
            <strong>III</strong> - Flat DEF
          </li>
          <li>
            <strong>IV</strong> - HP%, ATK%, DEF%, CRIT Rate%, CRIT DMG%,
            Anomaly Proficiency
          </li>
          <li>
            <strong>V</strong> - HP%, ATK%, DEF%, PEN Ratio%, Element DMG%*
          </li>
          <ul>
            <li>
              *Element DMG is random and can roll any of the 5 elements
              available in the game,
            </li>
          </ul>
          <li>
            <strong>VI</strong> - HP%, ATK%, DEF%, Anomaly Mastery%, Energy
            Regen%, Impact%
          </li>
        </ul>
        <h5>Sub Stats</h5>
        <p>
          Drive Disks sub-stats are randomly generated. The list of possible
          substats are:
        </p>
        <ul>
          <li>Flat HP</li>
          <li>Flat ATK</li>
          <li>Flat DEF</li>
          <li>HP%</li>
          <li>ATK%</li>
          <li>DEF%</li>
          <li>Crit Rate%</li>
          <li>Crit DMG%</li>
          <li>PEN (flat)</li>
          <li>Anomaly Proficiency</li>
        </ul>
        <SectionHeader title="Main stats" />
        <p>
          Each column shows the maximum value of the stat you can get based on
          the Disk rarity once you upgrade it - for S rank it's +15, for A rank
          it's +12 and for B rank it's +9.
        </p>
        <Tabs
          defaultActiveKey="disk-i"
          id="main-stats"
          className="hsr-tabs main-stats"
          transition={false}
        >
          <Tab eventKey="disk-i" title="Slot I">
            <Table striped bordered responsive="sm" className="zzz-stat-table">
              <thead>
                <tr>
                  <th className="first">Stat</th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_b.png"
                      alt="B Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_a.png"
                      alt="A Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_s.png"
                      alt="S Rank"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="first">
                    <ZZZStat stat="HP" />
                  </td>
                  <td>734</td>
                  <td>1468</td>
                  <td>2200</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="disk-ii" title="Slot II">
            <Table striped bordered responsive="sm" className="zzz-stat-table">
              <thead>
                <tr>
                  <th className="first">Stat</th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_b.png"
                      alt="B Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_a.png"
                      alt="A Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_s.png"
                      alt="S Rank"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="first">
                    <ZZZStat stat="ATK" />
                  </td>
                  <td>104</td>
                  <td>212</td>
                  <td>316</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="disk-iii" title="Slot III">
            <Table striped bordered responsive="sm" className="zzz-stat-table">
              <thead>
                <tr>
                  <th className="first">Stat</th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_b.png"
                      alt="B Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_a.png"
                      alt="A Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_s.png"
                      alt="S Rank"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="first">
                    <ZZZStat stat="DEF" />
                  </td>
                  <td>60</td>
                  <td>124</td>
                  <td>184</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="disk-iv" title="Slot IV">
            <Table striped bordered responsive="sm" className="zzz-stat-table">
              <thead>
                <tr>
                  <th className="first">Stat</th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_b.png"
                      alt="B Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_a.png"
                      alt="A Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_s.png"
                      alt="S Rank"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="first">
                    <ZZZStat stat="ATK%" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="HP%" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="DEF%" />
                  </td>
                  <td>16%</td>
                  <td>32%</td>
                  <td>48%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="Anomaly Proficiency" />
                  </td>
                  <td>32</td>
                  <td>60</td>
                  <td>92</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="CRIT Rate" />
                  </td>
                  <td>8%</td>
                  <td>16%</td>
                  <td>24%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="CRIT DMG" />
                  </td>
                  <td>16%</td>
                  <td>32%</td>
                  <td>48%</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="disk-v" title="Slot V">
            <Table striped bordered responsive="sm" className="zzz-stat-table">
              <thead>
                <tr>
                  <th className="first">Stat</th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_b.png"
                      alt="B Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_a.png"
                      alt="A Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_s.png"
                      alt="S Rank"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="first">
                    <ZZZStat stat="ATK%" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="HP%" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="DEF%" />
                  </td>
                  <td>16%</td>
                  <td>32%</td>
                  <td>48%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="PEN Ratio" />
                  </td>
                  <td>8%</td>
                  <td>16%</td>
                  <td>24%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="Ether DMG" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="Electric DMG" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="Physical DMG" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="Ice DMG" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="Fire DMG" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="disk-vi" title="Slot VI">
            <Table striped bordered responsive="sm" className="zzz-stat-table">
              <thead>
                <tr>
                  <th className="first">Stat</th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_b.png"
                      alt="B Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_a.png"
                      alt="A Rank"
                    />
                  </th>
                  <th>
                    <StaticImage
                      src="../../../images/zzz/icons/rarity_s.png"
                      alt="S Rank"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="first">
                    <ZZZStat stat="ATK%" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="HP%" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="DEF%" />
                  </td>
                  <td>16%</td>
                  <td>32%</td>
                  <td>48%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="Anomaly Mastery" />
                  </td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="Energy Regen" />
                  </td>
                  <td>20%</td>
                  <td>40%</td>
                  <td>60%</td>
                </tr>
                <tr>
                  <td className="first">
                    <ZZZStat stat="Impact" />
                  </td>
                  <td>6%</td>
                  <td>12%</td>
                  <td>18%</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        </Tabs>
        <SectionHeader title="Sub stats" />
        <p>
          Since the{' '}
          <strong>
            sub-stat pool is the same no matter the Disk slot it fits, there's
            no point in splitting them
          </strong>
          . Just remember that you can't have the same main stat and sub-stat on
          the same item, for example if you've rolled HP% on the main stat, you
          won't be able to get HP% as one of the sub-stats (but you will be able
          to get flat HP as the game treats them as separate stats).
        </p>
        <h5>Sub-stats ranges</h5>
        <p>
          This table shows the possible sub-stats values you can get{' '}
          <strong>per one Enhance</strong>. This means the base sub-stats values
          of a Drive Disk you've just obtained will be shown here and also every
          time you Enhance a Drive Disk,{' '}
          <strong>one of the sub-stats will increase by that amount</strong>. As
          usual, higher rarity Drive Disk will have higher ranges for the
          sub-stats. Compared to Honkai: Star Rail,{' '}
          <strong>the sub-stats values are static</strong> - it means that if
          you roll a stat, it will always increase by the same static value
          every single time. Gone are the days of random rolls that can sour
          your day even if you get the stat you want, but low roll the value.
        </p>
        <p>
          <strong>Important! </strong> If you've got a Drive Disk that rolled
          different values, please let us know and we will fix the data below.
        </p>
        <Table striped bordered responsive="sm" className="zzz-stat-table">
          <thead>
            <tr>
              <th className="first">Stat</th>
              <th>
                <StaticImage
                  src="../../../images/zzz/icons/rarity_b.png"
                  alt="B Rank"
                />
              </th>
              <th>
                <StaticImage
                  src="../../../images/zzz/icons/rarity_a.png"
                  alt="A Rank"
                />
              </th>
              <th>
                <StaticImage
                  src="../../../images/zzz/icons/rarity_s.png"
                  alt="S Rank"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="first">
                <ZZZStat stat="ATK" />
              </td>
              <td>7</td>
              <td>15</td>
              <td>19</td>
            </tr>
            <tr>
              <td className="first">
                <ZZZStat stat="ATK%" />
              </td>
              <td>1%</td>
              <td>2%</td>
              <td>3%</td>
            </tr>
            <tr>
              <td className="first">
                <ZZZStat stat="HP" />
              </td>
              <td>39</td>
              <td>79</td>
              <td>112</td>
            </tr>
            <tr>
              <td className="first">
                <ZZZStat stat="HP%" />
              </td>
              <td>1%</td>
              <td>2%</td>
              <td>3%</td>
            </tr>
            <tr>
              <td className="first">
                <ZZZStat stat="DEF" />
              </td>
              <td>5</td>
              <td>10</td>
              <td>15</td>
            </tr>
            <tr>
              <td className="first">
                <ZZZStat stat="DEF%" />
              </td>
              <td>1.6%</td>
              <td>3.2%</td>
              <td>4.8%</td>
            </tr>
            <tr>
              <td className="first">
                <ZZZStat stat="CRIT Rate" />
              </td>
              <td>0.8%</td>
              <td>1.6%</td>
              <td>2.4%</td>
            </tr>
            <tr>
              <td className="first">
                <ZZZStat stat="CRIT DMG" />
              </td>
              <td>1.6%</td>
              <td>3.2%</td>
              <td>4.8%</td>
            </tr>
            <tr>
              <td className="first">
                <ZZZStat stat="PEN" />
              </td>
              <td>3</td>
              <td>6</td>
              <td>9</td>
            </tr>
            <tr>
              <td className="first">
                <ZZZStat stat="Anomaly Proficiency" />
              </td>
              <td>3</td>
              <td>6</td>
              <td>9</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZAgentsDiskDrivesStats;

export const Head: React.FC = () => (
  <Seo
    title="Drive Disks Stats | Zenless Zone Zero | Prydwen Institute"
    description="Drive Disks Stats from Zenless Zone Zero explained - everything you can roll on the gear explained."
    game="zzz"
  />
);
